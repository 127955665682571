<template>
	<div class="page">
		<div class="titBox">
			<p class="title">{{lang==6?'宝爱捷（中国）培训学院':'PAIG （ China ）Training Academy'}}</p>
			<p class="yellowLine"></p>
		</div>

		<div class="content">
			<p class="tit">{{name}}</p>
			<div v-if="type!=5">
				<div class="flex" v-if="lang==6">
					<img :src="require('../../../assets/AFCC/ETTD'+type+'.png')" />
					<div>
						<img :src="images[0]" />
						<img :src="images[1]" />
					</div>
				</div>
				<div class="flex flexEN" v-else>
					<img :src="require('../../../assets/AFCC/ETTD'+type+'EN.png')" />
					<div>
						<img :src="images[0]" />
						<img :src="images[1]" />
					</div>
				</div>
			</div>
			<div v-else class="ettd5">
				<div v-if="lang==6">
					“扬帆计划”是宝爱捷集团对BEV销售人才培养整体计划中对基础岗位人才储备的一个起点。公司将岗位基础课程嵌入到在校学生的学习安排中，公司将为项目提供教程、内训师，通过全脱产培训、严格考核、双向选择，为集团经销商储备优秀人才。
				</div>
				<div v-else>
					"Super Young" plan is a starting point for the reserve of basic positions in the overall plan of BEV
					sales talent training of PAIG. The Company embeds the basic courses of the post into the learning
					arrangement of the students, and the company will provide tutorials and professional internal
					trainers for the project, and reserve outstanding talents for the group's dealers through full-time
					training, strict assessment and two-way selection.
				</div>
				<img :src="require('../../../assets/AFCC/ETTD5'+(lang==6?'':'EN')+'.png')" />
			</div>
			<div class="btn" @click="$router.replace('/aboutUs/AFCC')">
				< {{lang==6?'返回':'Back'}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {
				type: 1,
				images: []
			}
		},
		mounted() {},
		computed: {
			name() {
				if (this.type == 1) return this.lang == 6 ? '人才发展国际项目' : 'Talent Development International Programs'
				if (this.type == 2) return this.lang == 6 ? '人才加速及成长项目' : 'Talent Speed-Up & Growth Programs'
				if (this.type == 3) return this.lang == 6 ? '专业能力提升训练营' : 'Professional Skills Improvement Training Camp'
				if (this.type == 5) return this.lang == 6 ? '扬帆计划' : '"Super Young" PLAN'
			}
		},
		methods: {
			getInfo() {
				this.type = this.$route.query.type ? this.$route.query.type : 1
				this.$api.getConfigure({
					type: this.type == 1 ? 17 : (this.type == 2) ? 18 : 19
				}).then(res => {
					this.images = res.data.image.split('|')
				})
			},
		}

	}
</script>

<style lang="scss" scoped>
	.page {
		padding: 171px 0 111px;
		position: relative;
	}

	.content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px 40px;
	}

	.title {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 32px;
		font-weight: bold;
		margin-bottom: 20px;
		text-transform: uppercase;
		color: #FFF;
	}

	.titBox {
		padding: 0 111px;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	.flex {
		display: flex;
		position: relative;

		>img {
			height: 720px;
			width: auto;
		}

		div {
			img {
				position: absolute;
				width: 508px;
				height: 330px;
				border-radius: 16px;

				&:nth-child(1) {
					right: 76px;
					top: 40px;
				}

				&:nth-child(2) {
					right: 330px;
					bottom: 40px;
				}
			}
		}
	}

	.flexEN {
		>img {
			transform: translateX(-20px);
		}

		div {
			img:nth-child(1) {
				right: 20px;
				top: 40px;
			}

			img:nth-child(2) {
				right: 220px;
				bottom: 40px;
			}
		}
	}

	.tit {
		font-family: SourceHanSansCN-Medium, sans-serif;
		font-size: 32px;
		font-weight: bold;
		color: #97233F;
	}

	.ettd5 {
		div {
			margin: 30px 0 50px;
		}

		img {
			width: 95%;
			display: block;
			margin: 0 auto 80px;
		}
	}

	.btn {
		text-align: right;
		font-weight: bold;
		font-size: 24px;
		cursor: pointer;
	}
</style>